import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import {
  dataabout,
  meta,
  worktimeline,
  skills,
  services,
} from "../../content_option";

export const About = () => {
  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> About | {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3 pt-md-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">About me</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="5">
            <h3 className="color_sec py-4">{dataabout.title}</h3>
          </Col>
          <Col lg="7" className="d-flex align-items-center">
            <div>
              <p>{dataabout.aboutme}</p>
            </div>
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lang="5">
            <h3 className="color_sec py-4">other bit of me</h3>
          </Col>
          <Col lg="7">
            Out of computers, I enjoy Arts, contemporary literature & taking very long walks to sometimes not a defined destination.
            <br></br>
            <br></br>
            I love sharing the road so if you are around Buea, Cameroon and will like to take a walk with someone, Reach out :)
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="5">
            <h3 className="color_sec py-4">skills</h3>
          </Col>
          <Col lg="7">
            Go, Python, Javascript/Typescript, React.js, Node.js, Flask, Django, Gin, Linux, Docker, Kubernetes, Terraform, CI/CD, AWS.
          </Col>
        </Row>
      </Container>
    </HelmetProvider>
  );
};
